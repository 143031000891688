import React, { createContext, useContext, useEffect, useState } from "react";

// Create the context with default values
interface AppContextProps {
  currentSectionId: string | null;
}

const AppContext = createContext<AppContextProps | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentSectionId, setCurrentSectionId] = useState<string | null>(null);

  useEffect(() => {
    // Callback to handle the intersection events
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      // Loop through entries and find the first intersecting one
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute("data-section-id");
          if (sectionId) {
            setCurrentSectionId(sectionId);
          }
        }
      });
    };

    // Create the observer with a threshold to determine when an element is in view
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5, // Customize the visibility threshold (50% visible)
    });

    // Observe elements with the attribute `data-section-id`
    const elementsToObserve = document.querySelectorAll("[data-section-id]");
    elementsToObserve.forEach((element) => observer.observe(element));

    // Cleanup on unmount
    return () => {
      elementsToObserve.forEach((element) => observer.unobserve(element));
    };
  }, []);

  return <AppContext.Provider value={{ currentSectionId }}>{children}</AppContext.Provider>;
};

// Custom hook to use the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
