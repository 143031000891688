import { Box, Button, ButtonGroup, Center, Flex, HStack, useBreakpointValue, useDisclosure } from "@chakra-ui/react";
import { MobileDrawer } from "./MobileDrawer";
import { ToggleButton } from "./ToggleButton";
import { pages } from "../../constants";

export const Navbar = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();

  const onNavbarItemClick = (href: string) => {
    // scroll to the id contact-form
    const element = document.getElementById(href);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box as="nav">
      <Box bg="primary" position="relative">
        <Box py="3" px="7" w="100%">
          <Center>
            {isDesktop ? (
              <HStack spacing="8">
                <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing={{ lg: "1", xl: "2" }}>
                  {pages.map((item) => (
                    <Button
                      key={item.id}
                      fontSize="2xl"
                      display={item.hideNavbar ? "none" : "block"}
                      fontWeight={400}
                      color="secondary"
                      onClick={() => {
                        onNavbarItemClick(item.id);
                      }}
                    >
                      {item.name}
                    </Button>
                  ))}
                </ButtonGroup>
              </HStack>
            ) : (
              <Flex justifyContent="flex-end" w="100%">
                <ToggleButton color="secondary" onClick={mobileNavbar.onToggle} isOpen={mobileNavbar.isOpen} aria-label="Open Menu" zIndex="tooltip" />
                <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />
              </Flex>
            )}
          </Center>
        </Box>
      </Box>
    </Box>
  );
};
