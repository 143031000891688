import React, { useEffect } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./theme";
import { Page, Layout } from "./pages";
import { AppProvider } from "./context/AppContext";
import ReactGA from "react-ga4";
import "./index.css";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-ZGFZZ0LX58");
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <AppProvider>
        <Layout>
          <Page />
        </Layout>
      </AppProvider>
    </ChakraProvider>
  );
}

export default App;
