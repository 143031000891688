import { Grid, GridItem, Heading, Img, Text } from "@chakra-ui/react";

export const Hero = () => {
  return (
    <Grid data-section-id="home" id="home" gap="20%" templateRows="3fr 1fr" h="auto" mb="240px" bgColor="primary">
      <GridItem as={Grid} templateColumns={"repeat(4, 1fr)"}>
        {/* 4 images side by side */}
        <GridItem colSpan={1}>
          <Img src="https://images.unsplash.com/photo-1728022793588-a588b92bf063?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw1M3x8fGVufDB8fHx8fA%3D%3D" alt="hero-image" height="100%" width="100%" objectFit="cover" />
        </GridItem>
        <GridItem colSpan={1}>
          <Img src="https://images.unsplash.com/photo-1491975474562-1f4e30bc9468?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fG1hcmtldGluZ3xlbnwwfHwwfHx8MA%3D%3D" alt="hero-image" height="100%" width="100%" objectFit="cover" />
        </GridItem>
        <GridItem colSpan={1}>
          <Img src="https://images.unsplash.com/photo-1713877184702-34141e5b299d?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHw4fHx8ZW58MHx8fHx8" alt="hero-image" height="100%" width="100%" objectFit="cover" />
        </GridItem>
        <GridItem colSpan={1}>
          <Img src="https://images.unsplash.com/photo-1727461144658-b4baf3b591bf?w=400&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxmZWF0dXJlZC1waG90b3MtZmVlZHwyOXx8fGVufDB8fHx8fA%3D%3D" alt="hero-image" height="100%" width="100%" objectFit="cover" />
        </GridItem>
      </GridItem>
      <GridItem>
        <Heading as="h1" size="4xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
          SOCIALS BY HOLS
        </Heading>
        <Text mt={5} fontSize="xl" fontFamily="Bebas Neue" color="secondary" textAlign="center">
          Social Media Marketing Management
        </Text>
      </GridItem>
    </Grid>
  );
};
