import { Grid, GridItem } from "@chakra-ui/react";
import { Footer, Navbar } from "../components";
import React from "react";
import { Sidebar } from "../components/Sidebar";

interface LayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Grid height="100vh" width="100vw" bg="primary" templateColumns={{ base: "minmax(50px, 1fr) 9fr", md: "minmax(100px, 1fr) 9fr" }} templateRows="auto 1fr">
        {/* Top-left item */}
        <GridItem w="100%" h="100%" borderRightWidth={3} borderColor="secondary" borderStyle="double" borderBottomWidth={3}></GridItem>

        {/* Top-right item with Navbar */}
        <GridItem w="100%" h="100%" borderBottomWidth={3} borderColor="secondary" borderBottomStyle="double" colStart={2} colEnd={3}>
          <Navbar />
        </GridItem>

        {/* Bottom-left item */}
        <GridItem w="100%" h="100%" borderRightWidth={3} borderColor="secondary" borderStyle="double" rowStart={2} rowEnd={3}>
          <Sidebar />
        </GridItem>

        {/* Bottom-right item with children and Footer */}
        <GridItem w="100%" h="100%" colStart={2} colEnd={3} rowStart={2} overflowY="scroll">
          {children}
          <Footer />
        </GridItem>
      </Grid>
    </>
  );
};
