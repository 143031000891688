import { extendTheme } from "@chakra-ui/react";

// Call `extendTheme` and pass your custom values
export const theme = extendTheme({
  fonts: {
    heading: "Roboto, sans-serif",
    body: "Roboto, sans-serif",
  },
  colors: {
    primary: "#f5f5ec",
    primaryAccent: "#f28b30",
    secondary: "#2b3a42",
    tertiary: "#eae8dd",
  },
  components: {
    Button: {
      variants: {
        primary: {
          bg: "primary",
          color: "white",
          _hover: {
            bg: "blue.600",
          },
        },
        secondary: {
          bg: "secondary",
          color: "white",
          _hover: {
            bg: "orange.600",
          },
        },
      },
    },
  },
});
