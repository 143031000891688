import { Center, Grid, GridItem, Heading, Image, Text } from "@chakra-ui/react";

export const Why = () => {
  return (
    <Grid data-section-id="why" id="why" minH="100vh" mt={20} templateColumns={{ base: "1fr", md: "1fr 1.5fr" }} w="100%" height="100%">
      <GridItem p={{ base: "5%", md: "10%" }} order={{ base: 2, md: 1 }}>
        <Image src="https://plus.unsplash.com/premium_photo-1683749805319-2c481ae54bc1?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8c3VjY2Vzc3xlbnwwfHwwfHx8MA%3D%3D" alt="about-image" borderRadius="lg" height="100%" width="100%" objectFit="cover" />
      </GridItem>
      <GridItem borderRadius="lg" bg="tertiary" as={Center} flexDir="column" padding={{ base: "10%", md: "5%" }} order={{ base: 1, md: 2 }}>
        <Heading as="h1" size="2xl" fontFamily="Bebas Neue" textTransform="uppercase" fontWeight={600} color="secondary" textAlign="center">
          Why You Should Work with Me as Your Social Media Manager
        </Heading>
        <Text mt={5} fontSize="2xl" lineHeight={1.7}>
          Managing a successful social media presence takes time, creativity, and a solid strategy, which is where I come in. I specialise in crafting visually engaging content and tailored social media strategies for beauty, skincare, fashion, home, and travel brands. By working with me, you’ll benefit from a consistent, professional presence online that resonates with your audience and aligns with your brand’s style and goals. I’m here to take the stress out of social media, creating and
          managing posts, captions, engagement, and more—so you can focus on running your business. Together, we’ll build a social media presence that not only stands out but also drives meaningful connections and growth for your brand.
        </Text>
      </GridItem>
    </Grid>
  );
};
