import { Box } from "@chakra-ui/react";
import { About, Hero } from "../components";
import { MyValues } from "../components/pages/MyValues";

import { Why } from "../components/pages/Why";
import { Portfolio } from "../components/pages/Portfolio";

export const Page = () => (
  <Box bg="primary" w="100%">
    <Hero />
    <About />
    <MyValues />
    <Portfolio />
    <Why />
    {/* <WhoAreWe /> */}
    {/* <HowCanWeHelp /> */}
    {/* <NeedSomethingUnique /> */}
    {/* <About /> */}
    {/* <Features /> */}
    {/* <Contact /> */}
  </Box>
);
