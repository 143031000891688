import { Center, Grid, GridItem, Heading, Image, Text } from "@chakra-ui/react";

export const About = () => {
  return (
    <Grid data-section-id="about-me" id="about-me" h="auto" templateRows={{ base: "1fr 1fr", md: "1fr" }} templateColumns={{ base: "1fr", md: "1fr 1fr" }} w="100%">
      <GridItem>
        <Image src="https://plus.unsplash.com/premium_photo-1681488007344-c75b0cf8b0cd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8Y3JlYXRpdmV8ZW58MHx8MHx8fDA%3D" alt="about-image" height="100%" width="100%" objectFit="cover" />
      </GridItem>
      <GridItem as={Center} flexDir="column" padding={"5%"}>
        <Heading as="h1" size="2xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="left">
          Who I Am
        </Heading>
        <Text mt={5} fontSize="xl">
          I’m Hollie, a passionate social media manager and content creator dedicated to helping small businesses shine online. With a foundation in digital and social media marketing, I offer customised social media management packages as well as individual services to support businesses that need affordable, effective social media solutions. My goal? To take the stress out of managing your socials so you can focus on what you do best!
        </Text>
        <Heading mt={10} as="h1" size="2xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="left">
          What I Do
        </Heading>
        <Text mt={5} fontSize="xl">
          I specialize in crafting engaging, authentic content to resonate with your target audience, whether it’s through posts, stories, or videos. From creating tailored strategies to designing eye-catching visuals and writing compelling captions, I work closely with each client to amplify their brand presence and drive real connections with their customers.
        </Text>
        <Heading mt={10} as="h1" size="2xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="left">
          Why I Love What I Do
        </Heading>
        <Text mt={5} fontSize="xl">
          Social media allows me to combine creativity and strategy to tell compelling stories. Seeing my work make a real impact on brand growth and engagement is what drives me.{" "}
        </Text>
      </GridItem>
    </Grid>
  );
};
