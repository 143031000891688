import { Center, Grid, GridItem, Heading, Image } from "@chakra-ui/react";
const SocialMediaContent = require("../../assets/social-media-content.png");
const SocialMediaLaptopMock = require("../../assets/social-media-laptop-mock.png");

export const Portfolio = () => {
  return (
    <Grid data-section-id="portfolio" id="portfolio" gap={5} p={10} templateRows="auto auto auto" w="100%" height="auto">
      <Center>
        <Heading as="h1" size="4xl" fontFamily="Bebas Neue" fontWeight={600} color="secondary" textAlign="center">
          Portfolio
        </Heading>
      </Center>

      {/* 3x3 Image Grid */}
      <GridItem as={Grid} templateColumns={{ base: "1fr", md: "1fr 1fr" }} px={{ base: "10%", md: "20%" }} gap={10}>
        <Image src={SocialMediaLaptopMock} alt="social-media-image" borderRadius="lg" height="auto" width="100%" objectFit="cover" />
        <Image src={SocialMediaContent} alt="social-media-image" borderRadius="lg" height="auto" width="100%" objectFit="cover" />
      </GridItem>

      {/* Single Row of Four Images */}
    </Grid>
  );
};
