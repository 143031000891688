import { Box, Center, Grid, GridItem, Link, Text, Icon, VStack, Heading } from "@chakra-ui/react";
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";
import { pages } from "../constants";

const certificate1 = require("../assets/certificate-1.pdf");
const certificate2 = require("../assets/certificate-2.pdf");
const certificate3 = require("../assets/certificate-3.pdf");
const certificate4 = require("../assets/certificate-4.pdf");

export const Footer = () => {
  const onFooterItemClick = (href: string) => {
    const element = document.getElementById(href);
    element?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box as="footer" bg="primary" color="black" py={10} px={{ base: 5, md: 20 }}>
      <Grid templateColumns={{ base: "1fr", md: "repeat(3, 1fr)" }} gap={10} textAlign={{ base: "center", md: "left" }}>
        {/* Navigation Links */}
        <GridItem>
          <Heading as="h3" size="md" mb={4} color="secondary" fontFamily="Bebas Neue">
            Navigation
          </Heading>
          <Box>
            {pages.map((item) => (
              <Link key={item.id} fontSize="lg" display={item.hideNavbar ? "none" : "block"} _hover={{ textDecoration: "underline", color: "secondary" }} onClick={() => onFooterItemClick(item.id)}>
                {item.name}
              </Link>
            ))}
          </Box>
        </GridItem>

        {/* Certificate Links */}
        <GridItem>
          <Heading as="h3" size="md" mb={4} color="secondary" fontFamily="Bebas Neue">
            My Certifications
          </Heading>
          <VStack align={{ base: "center", md: "flex-start" }}>
            <Link href={certificate1} fontSize="lg" _hover={{ textDecoration: "underline", color: "secondary" }} target="_blank" rel="noopener noreferrer" download>
              Certificate 1
            </Link>
            <Link href={certificate2} fontSize="lg" _hover={{ textDecoration: "underline", color: "secondary" }} target="_blank" rel="noopener noreferrer" download>
              Certificate 2
            </Link>
            <Link href={certificate3} fontSize="lg" _hover={{ textDecoration: "underline", color: "secondary" }} target="_blank" rel="noopener noreferrer" download>
              Certificate 3
            </Link>
            <Link href={certificate4} fontSize="lg" _hover={{ textDecoration: "underline", color: "secondary" }} target="_blank" rel="noopener noreferrer" download>
              Certificate 4
            </Link>
          </VStack>
        </GridItem>

        {/* Social Media Icons */}
        <GridItem>
          <Heading as="h3" size="md" mb={4} color="secondary" fontFamily="Bebas Neue">
            Follow Us
          </Heading>
          <Box display="flex" justifyContent={{ base: "center", md: "flex-start" }} gap={5}>
            <Link href="https://www.instagram.com/socialsbyhols/" isExternal>
              <Icon as={FaInstagram} boxSize={6} color="secondary" _hover={{ color: "black" }} />
            </Link>
            <Link href="https://www.linkedin.com/in/socials-by-hols-75a230324/" isExternal>
              <Icon as={FaLinkedin} boxSize={6} color="secondary" _hover={{ color: "black" }} />
            </Link>
          </Box>
        </GridItem>
      </Grid>

      {/* Copyright Section */}
      <Center mt={10}>
        <Text fontSize="sm">&copy; {new Date().getFullYear()} Hollie’s Social Media Services. All rights reserved.</Text>
      </Center>
    </Box>
  );
};
